<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    max-width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        style="color: #363533; height: 30px"
        :outlined="!form[name] && !never_outlined"
        block
        elevation="0"
        :disabled="disabled"
        @click="open"
        :style="btnStyle"
        :color="error ? 'red' : 'primary'"
        >{{ text }}</v-btn
      ></template
    >
    <v-card>
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        class="pb-2"
        >{{ text }}</v-card-title
      >
      <vueSignature
        class="firma"
        ref="signature"
        :sigOption="option"
        :h="height"
        :w="width"
      ></vueSignature>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          @click="cancel()"
          style="height: 25px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          elevation="0"
          @click="clear()"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
        >
          {{ $t("erase", { name: $t("") }) }}
        </v-btn>
        <v-btn
          elevation="0"
          @click="save()"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xs" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "PopUpFirma",
  components: {
    vueSignature: () => import("vue-signature"),
  },
  props: [
    "form",
    "name",
    "text",
    "error",
    "btnStyle",
    "disabled",
    "cancel_not_delete",
    "never_outlined",
  ],
  data() {
    return {
      dialog: false,
      option: {},
    };
  },
  mounted() {
    // this.$refs.signature.fromDataUrl(this.form[this.name]);
  },
  computed: {
    height() {
      if (this.$vuetify.breakpoint.xs) return "70vh";
      return "400px";
    },
    width() {
      if (this.$vuetify.breakpoint.xs) return "100%";
      return "95%";
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
      if (this.cancel_not_delete) return;
      //this.clear();
    },

    open() {
      this.dialog = true;
      if (this.form[this.name]) {
        setTimeout(() => {
          this.$refs.signature.fromDataURL(this.form[this.name]);
        }, 100);
      }
    },
    clear() {
      this.$refs.signature.clear();
      this.form[this.name] = undefined;
    },
    save() {
      var svg = this.$refs.signature.save("image/svg+xml");
      this.$set(this.form, this.name, svg);
      this.dialog = false;
      this.$emit("save");
    },
  },
};
</script>
<style lang="sass">
@media (min-width: 960px)
  .firma
    border: 2px solid rgb(217,178,105)
    border-radius: 20px
    overflow: hidden
    margin: 2.5%
    canvas.canvas
      border-radius: 20px
</style>